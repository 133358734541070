import React, {useState, useEffect} from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {Form, Button} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { login } from '../actions/userActions'

function LoginScreen() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  
  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const redirect = location.search ? location.search.split('=')[1] : '/';

  const userLogin = useSelector(state => state.userLogin);
  const { error, loading, userInfo } = userLogin;

  useEffect(() => {
      if (userInfo) {
          navigate(redirect);
      }
  }, [navigate, userInfo, redirect]);

  const submitHandler = (e) => {
      e.preventDefault()
      dispatch(login(email, password))
  }

  return (
    <main id="main">
      <section id="signinform" className="signinform">
        <div className="container">
          <div className="section-title-signinform">
            <h2>Sign in</h2>
            <p>Or <Link to={redirect ? `/register?redirect=${redirect}` : '/register'}>Create an Account</Link></p>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 mt-5 mt-lg-0 d-flex align-items-stretch">
              <Form onSubmit={submitHandler} className="signin-form">
                {error && <Message variant='danger'>{error}</Message>}
                {loading && <Loader />}

                <Form.Group controlId='email'>
                    <Form.Control
                        required
                        type='email'
                        placeholder='Email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>
              
                <Form.Group controlId='password' className="pt-3">
                      <Form.Control
                          required
                          type='password'
                          placeholder='Password'
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                      >
                      </Form.Control>
                  </Form.Group>
                
                <div className="text-left mt-3"><Button type="submit">Log In</Button></div>
                <p className="text-left pt-3"><Link to={'/forgot-password'}>Forgot Password?</Link></p>
              </Form>

            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default LoginScreen
