import React, { useEffect,useState } from 'react'
import { NavLink } from "react-router-dom";
import { useLocation, useNavigate } from 'react-router-dom';

function VerificationSuccessScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const uidb64 = queryParams.get('uidb64');
    const token = queryParams.get('token');

    if (!uidb64 || !token) {
        navigate('/');
        return;
    }

    // fetch(`http://127.0.0.1:8000/accounts/activate/${uidb64}/${token}`)
    fetch(`/accounts/activate/${uidb64}/${token}`)
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            setMessage(data.message);
        } else {
            navigate('/failure');  // Redirect if token verification fails
        }
    })
    .catch(error => {
        console.error('Error:', error);
        navigate('/failure');  // Redirect on error
    })
    .finally(() => {
        setIsLoading(false);
    });
  }, [navigate, location.search]);

  return (
  <main id="main">
    <section id="cta" className="cta">
      <div className="container">
        <div className="section-title">
        </div>
        <div className="text-center">
          <h3>Email Verification Successful!</h3>
          <p> Thank you for verifying your email. You can now log in to your account.</p>
          <NavLink className="cta-btn" to="/login">Login</NavLink>
        </div>
      </div>
    </section>
  </main>
  )
}

export default VerificationSuccessScreen
