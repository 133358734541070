import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import {Form, Button} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { forgotPassword } from '../actions/userActions'

function ForgotPasswordEmailScreen() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userPasswordReset = useSelector(state => state.userPasswordReset);
  const { error, loading } = userPasswordReset;
  
  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      navigate('/reset-password');
    }   
  }, [navigate, userInfo]);

  const submitHandler = (e) => {
      e.preventDefault()
      dispatch(forgotPassword(email));
      setSubmitted(true);
  }

  return (
        <main id="main">
          <section className="breadcrumbs">
              <div className="container">
                  <div className="d-flex justify-content-between align-items-center">
                      <h2>Forgot Your Password?</h2>
                  </div>
              </div>
          </section>

          <section id="profile" className="profile">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-lg-5 d-flex align-items-stretch">
                            <div className = "info">
                                {!submitted ? (
                                    <Form onSubmit={submitHandler} className="signin-form">
                                        {message && <Message variant='danger'>{message}</Message>}
                                        {error && <Message variant='danger'>{error}</Message>}
                                        {loading && <Loader />}
                                            
                                            <Form.Group controlId='email'>
                                                <Form.Control
                                                    required
                                                    type='email'
                                                    placeholder='Enter the email associated with your account.'
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                >
                                                </Form.Control>
                                            </Form.Group>
                                        
                                        <div className="text-left mt-3"><Button type="submit">Submit</Button></div>

                                    </Form>
                                ) : (
                                    <div>
                                        <p>
                                            Thank you. If this email is registered, you will receive a password reset link.<br /><br />
                                        
                                            <Button onClick={() => {
                                                setSubmitted(false);  // Reset the form
                                                navigate('/');
                                            }}>Go to Home</Button>
                                        </p>                                        
                                    </div>
                                )}
                          </div>
                      </div>
                  </div>
              </div>
          </section>
        </main>
  )
}

export default ForgotPasswordEmailScreen
