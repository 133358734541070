import React from 'react'
import { NavLink } from 'react-router-dom'
import {useSelector} from 'react-redux'

function ProfileScreen() {    
    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    if (!userInfo) {
        return <div>Loading or not authorized...</div>;
    }   

    return (
        <main id="main">
            <section className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2>Hi, {userInfo.first_name + ' ' + userInfo.last_name}</h2>
                        <ol>
                            <li><NavLink to="/dashboard">My Dashboard</NavLink></li>
                        </ol>
                    </div>
                </div>
            </section>

            <section id="profile" className="profile">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 d-flex align-items-stretch">
                            <div className="info">
                                <div className="name">
                                    <i className="bi bi-person-circle" />
                                    <h4>Name:</h4>
                                    <p>{userInfo.first_name + ' ' + userInfo.last_name}</p>
                                </div>
                                <div className="email">
                                    <i className="bi bi-envelope" />
                                    <h4>Email:</h4>
                                    <p>{userInfo.email}</p>
                                </div>
                                <div className="org">
                                    <i className="bi bi-building" />
                                    <h4>Organization:</h4>
                                    <p>{userInfo.orgName}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-5 d-flex align-items-stretch">
                            <div className="info">
                                <NavLink to="/reset-password"><h4>Reset Password</h4></NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
  }
  
  export default ProfileScreen