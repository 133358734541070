import React, { useEffect } from 'react'
import { NavLink , useNavigate } from "react-router-dom";

function RegisterSuccessScreen() {
  const navigate = useNavigate();
  
  useEffect(() => {
    // Check if the user has recently registered
    const recentlyRegistered = localStorage.getItem('recentlyRegistered');
    if (!recentlyRegistered) {
      navigate('/');  // Redirect to home or /register if they haven't actually registered
    }

    // Optional: Clear the flag after checking
    localStorage.removeItem('recentlyRegistered');
  }, [navigate]);
  
  return (
  <main id="main">
    <section id="cta" className="cta">
      <div className="container">
        <div className="section-title">
        </div>
        <div className="text-center">
          <h3>Thank you for registering your account.</h3>
          <p> Please verify your account by clicking on the verification link that you will shortly receive in your email.</p>
          <NavLink className="cta-btn" to="/">Tiger HCM</NavLink>
        </div>
      </div>
    </section>
  </main>
  )
}

export default RegisterSuccessScreen
