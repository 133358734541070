import React from 'react'
import { NavLink } from "react-router-dom";

function FailureScreen() {  
  return (
  <main id="main">
    <section id="cta-failure" className="cta-failure">
      <div className="container">
        <div className="section-title">
        </div>
        <div className="text-center">
          <h3>Error Encountered!</h3>
          <p>There was an error servicing your request. Please contact your system administrator.</p>
          <NavLink className="cta-failure-btn" to="/">Tiger HCM</NavLink>
        </div>
      </div>
    </section>
  </main>
  )
}

export default FailureScreen
