import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    
    USER_LOGOUT,

    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,
    USER_REGISTER_RESET,

    USER_UPDATE_PASSWORD_REQUEST,
    USER_UPDATE_PASSWORD_SUCCESS,
    USER_UPDATE_PASSWORD_FAIL,
    USER_UPDATE_PASSWORD_RESET,

    USER_PASSWORD_RESET_REQUEST,
    USER_PASSWORD_RESET_SUCCESS,
    USER_PASSWORD_RESET_FAIL,

    USER_PASSWORD_SET_REQUEST,
    USER_PASSWORD_SET_SUCCESS,
    USER_PASSWORD_SET_FAIL,
} from '../constants/userConstants'

export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return {loading: true}

        case USER_LOGIN_SUCCESS:
            return {loading: false, userInfo: action.payload}

        case USER_LOGIN_FAIL:
            return {loading: false, error: action.payload}

        case USER_LOGOUT:
            return {}

        default:
            return state
    }
}

export const userRegisterReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_REGISTER_REQUEST:
            return { loading: true }

        case USER_REGISTER_SUCCESS:
            return { loading: false, success: true}

        case USER_REGISTER_FAIL:
            return { loading: false, error: action.payload }

        case USER_LOGOUT:
            return {}

        case USER_REGISTER_RESET:
            return {}; // Reset the state            

        default:
            return state
    }
}

export const userUpdatePasswordReducer = (state = { }, action) => {
    switch (action.type) {
        case USER_UPDATE_PASSWORD_REQUEST:
            return { loading: true }

        case USER_UPDATE_PASSWORD_SUCCESS:
            return { loading: false, success: true, userInfo: action.payload }

        case USER_UPDATE_PASSWORD_FAIL:
            return { loading: false, error: action.payload }

        case USER_UPDATE_PASSWORD_RESET:
            return {} //Reset the state

        default:
            return state
    }
}

export const passwordResetReducer = (state = { }, action) => {
    switch (action.type) {
        case USER_PASSWORD_RESET_REQUEST:
            return { loading: true }

        case USER_PASSWORD_RESET_SUCCESS:
            return { loading: false, success: true, info: action.payload }

        case USER_PASSWORD_RESET_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const passwordSetReducer = (state = { }, action) => {
    switch (action.type) {
        case USER_PASSWORD_SET_REQUEST:
            return { loading: true };
            
        case USER_PASSWORD_SET_SUCCESS:
            return { loading: false, success: true, info: action.payload };

        case USER_PASSWORD_SET_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};