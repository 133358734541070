import React from 'react'

function Footer() {
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row  justify-content-center">
            <div className="col-lg-6">
              <h3>Connect with us for a Free Demo!</h3>
              <p>Still not sure? Connect with us today for a free demo.</p>
            </div>
          </div>
          <div className="row footer-newsletter justify-content-center">
            <div className="col-lg-6">
              <form action="" method="post">
                <input type="email" name="email" placeholder="Enter your Email" /><input type="submit" value="Get Started" />
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="container footer-bottom clearfix">
        <div className="copyright">
          © Copyright <strong><span>Tiger HCM</span></strong>. All Rights Reserved
        </div>
      </div>
    </footer>
  )
}

export default Footer
