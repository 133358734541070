import React, {useState, useEffect} from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import {Form, Button} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { updateUserPassword } from '../actions/userActions'
import { USER_UPDATE_PASSWORD_RESET } from '../constants/userConstants';

function ResetPasswordScreen() {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [message, setMessage] = useState('');

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const redirect = '/profile';

    const userLogin = useSelector(state => state.userLogin);
    const {userInfo } = userLogin;

    const userUpdatePassword = useSelector(state => state.userUpdatePassword);
    const { error, loading, success } = userUpdatePassword;

    useEffect(() => {
        // Redirect if user is not authenticated
        if (!userInfo) {
            navigate('/login');
        }
        
        if (success) {
            alert('Password changed successfully.');
            navigate(redirect);
        }

        return () => {
            // Dispatch the reset action when the component unmounts
            dispatch({ type: USER_UPDATE_PASSWORD_RESET });
          }; 
    }, [userInfo, success, navigate, dispatch, redirect]);

    const submitHandler = (e) => {
        e.preventDefault()
        
        if (newPassword !== confirmNewPassword) {
            setMessage('New passwords do not match')
        } else {
            dispatch(updateUserPassword(currentPassword, newPassword));
            setMessage('')
        }
    }

    if (!userInfo) {
        return <div>Loading or not authorized...</div>;
    }    

    return (
        <main id="main">
            <section className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2>Hi, {userInfo.first_name + ' ' + userInfo.last_name} - Reset Your Password</h2>
                        <ol>
                            <li><NavLink to="/dashboard">My Dashboard</NavLink></li>
                        </ol>
                    </div>
                </div>
            </section>

            <section id="profile" className="profile">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 d-flex align-items-stretch">
                            <div className = "info">
                                <Form onSubmit={submitHandler} className="signin-form">
                                    {message && <Message variant='danger'>{message}</Message>}
                                    {error && <Message variant='danger'>{error}</Message>}
                                    {loading && <Loader />}
                                
                                    <Form.Group controlId='currentPassword' className="pt-3">
                                        <Form.Control
                                            required
                                            type='password'
                                            placeholder='Current Password'
                                            value={currentPassword}
                                            onChange={(e) => setCurrentPassword(e.target.value)}
                                        >
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId='newPassword' className="pt-3">
                                        <Form.Control
                                            required
                                            type='password'
                                            placeholder='New Password'
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                        >
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId='confirmNewPassword' className="pt-3">
                                        <Form.Control
                                            required
                                            type='password'
                                            placeholder='Confirm New Password'
                                            value={confirmNewPassword}
                                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                                        >
                                        </Form.Control>
                                    </Form.Group>
                                    
                                    <div className="text-left mt-3"><Button type="submit">Submit</Button></div>

                                </Form>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-5 d-flex align-items-stretch">
                            <div className="info">
                                <NavLink to="/profile"><h4>Back to your profile</h4></NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
  }
  
  export default ResetPasswordScreen