import React, {useState, useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {Form, Button} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { register } from '../actions/userActions'
import { USER_REGISTER_RESET } from '../constants/userConstants';

function RegisterScreen() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [first_name, setFirst_name] = useState('');
  const [last_name, setLast_name] = useState('');
  const [org_code, setOrg_code] = useState('');
  const [message, setMessage] = useState('');
  
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const redirect = '/register-success';

  const userRegister = useSelector(state => state.userRegister);
  const { error, loading, success } = userRegister;

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      navigate('/profile');
    }  
    
    if (success) {
          navigate(redirect);
      }

      return () => {
        // Dispatch the reset action when the component unmounts
        dispatch({ type: USER_REGISTER_RESET });
      };      
  }, [dispatch, navigate, success, redirect, userInfo]);

  const submitHandler = (e) => {
      e.preventDefault()
      
      if (password !== confirmPassword) {
          setMessage('Passwords do not match')
      } else {
        dispatch(register(first_name, last_name, email, password, org_code))
      }
  }

  return (
    <main id="main">
      <section id="signinform" className="signinform">
        <div className="container">
          <div className="section-title-signinform">
            <h2>Sign Up - It's quick and easy.</h2>
            <p>Already have an account? <Link to='/login'>Sign In</Link></p>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 mt-5 mt-lg-0 d-flex align-items-stretch">
              <Form onSubmit={submitHandler} className="signin-form">
                {message && <Message variant='danger'>{message}</Message>}
                {error && <Message variant='danger'>{error}</Message>}
                {loading && <Loader />}

                <Form.Group controlId='first_name'>
                    <Form.Control
                        required
                        type='name'
                        placeholder='First Name'
                        value={first_name}
                        onChange={(e) => setFirst_name(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId='last_name' className="pt-3">
                    <Form.Control
                        required
                        type='name'
                        placeholder='Last Name'
                        value={last_name}
                        onChange={(e) => setLast_name(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>                

                <Form.Group controlId='email' className="pt-3">
                    <Form.Control
                        required
                        type='email'
                        placeholder='Email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>
              
                <Form.Group controlId='password' className="pt-3">
                    <Form.Control
                        required
                        type='password'
                        placeholder='Password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId='passwordConfirm' className="pt-3">
                    <Form.Control
                        required
                        type='password'
                        placeholder='Confirm Password'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>                

                <Form.Group controlId='org_code' className="pt-3">
                    <Form.Control
                        required
                        type='name'
                        placeholder='Your Organizational Code'
                        value={org_code}
                        onChange={(e) => setOrg_code(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>
                
                <div className="text-left mt-3"><Button type="submit">Register</Button></div>

              </Form>

            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default RegisterScreen
