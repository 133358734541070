import React, {useState, useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {Form, Button} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { setUserPassword } from '../actions/userActions'

function ResetPasswordUserScreen() {
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [message, setMessage] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const userPasswordSet = useSelector(state => state.userPasswordSet);
    const { error, loading, success } = userPasswordSet;

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const uidb64 = queryParams.get('uidb64');
        const token = queryParams.get('token');
    
        if (!uidb64 || !token) {
            navigate('/');
            return;
        }
      }, [navigate, location.search]);    

    const submitHandler = (e) => {
        e.preventDefault()
        
        if (newPassword !== confirmNewPassword) {
            setMessage('New passwords do not match')
        } else {

            const queryParams = new URLSearchParams(location.search);
            const uidb64 = queryParams.get('uidb64');
            const token = queryParams.get('token');

            dispatch(setUserPassword({uidb64, token, newPassword}));
            setMessage('')
        }
    }

    return (
        <main id="main">
            <section className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2>Set up your new password</h2>
                    </div>
                </div>
            </section>

            <section id="profile" className="profile">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 d-flex align-items-stretch">
                            <div className = "info">
                                {success ? (
                                    <div>
                                        <p>
                                            Password updated successfully. You can now log in.<br /><br />
                                        
                                            <Button onClick={() => {
                                                navigate('/login');
                                            }}>Login</Button>
                                        </p>                                        
                                    </div>
                                ) : (
                                    <Form onSubmit={submitHandler} className="signin-form">
                                        {message && <Message variant='danger'>{message}</Message>}
                                        {error && <Message variant='danger'>{error}</Message>}
                                        {loading && <Loader />}

                                        <Form.Group controlId='newPassword' className="pt-3">
                                            <Form.Control
                                                required
                                                type='password'
                                                placeholder='New Password'
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                            >
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId='confirmNewPassword' className="pt-3">
                                            <Form.Control
                                                required
                                                type='password'
                                                placeholder='Confirm New Password'
                                                value={confirmNewPassword}
                                                onChange={(e) => setConfirmNewPassword(e.target.value)}
                                            >
                                            </Form.Control>
                                        </Form.Group>
                                        
                                        <div className="text-left mt-3"><Button type="submit">Submit</Button></div>

                                    </Form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
  }
  
  export default ResetPasswordUserScreen