import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { NavLink} from "react-router-dom";
import { logout } from '../actions/userActions'

function Header() {
  const userLogin = useSelector(state => state.userLogin)
  const {userInfo} = userLogin

  const dispatch = useDispatch()

  const logoutHandler = () => {
    dispatch(logout())
  }

  return (
    <header id="header" className="fixed-top">
      <div className="container d-flex align-items-center justify-content-between">
        <h1 className="logo"><NavLink to="/">Tiger HCM</NavLink></h1>
        <nav id="navbar" className="navbar">
          <ul>
            <li><NavLink className="nav-link scrollto active" to="/">Home</NavLink></li>
            
            {userInfo ? (
              <li className="dropdown"><a href="#"><span>{userInfo.first_name + ' ' + userInfo.last_name}</span> <i className="bi bi-chevron-down" /></a>
                <ul>
                  <li><NavLink to = "/profile">My Profile</NavLink></li>
                  <li onClick = {logoutHandler}><NavLink to="/">Logout</NavLink></li>
                </ul>
              </li>
            ): 
            (<>
              <li className="dropdown"><a href="#"><span>Products</span> <i className="bi bi-chevron-down" /></a>
                <ul>
                  <li><a href="#">Drop Down 1</a></li>
                  <li className="dropdown"><a href="#"><span>Deep Drop Down</span> <i className="bi bi-chevron-right" /></a>
                    <ul>
                      <li><a href="#">Deep Drop Down 1</a></li>
                      <li><a href="#">Deep Drop Down 2</a></li>
                      <li><a href="#">Deep Drop Down 3</a></li>
                      <li><a href="#">Deep Drop Down 4</a></li>
                      <li><a href="#">Deep Drop Down 5</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Drop Down 2</a></li>
                  <li><a href="#">Drop Down 3</a></li>
                  <li><a href="#">Drop Down 4</a></li>
                </ul>
              </li>
            <li><a className="getstarted scrollto" href="#about">Get Started</a></li>
            <li><NavLink className="login scrollto" to="/login">Login</NavLink></li>
            </>)}          
          </ul>
          <i className="bi bi-list mobile-nav-toggle" />
        </nav>
      </div>
    </header>
  )
}

export default Header
