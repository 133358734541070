import axios from 'axios'
import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    
    USER_LOGOUT,

    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,
    USER_REGISTER_RESET,

    USER_UPDATE_PASSWORD_REQUEST,
    USER_UPDATE_PASSWORD_SUCCESS,
    USER_UPDATE_PASSWORD_FAIL,
    USER_UPDATE_PASSWORD_RESET,

    USER_PASSWORD_RESET_REQUEST,
    USER_PASSWORD_RESET_SUCCESS,
    USER_PASSWORD_RESET_FAIL,

    USER_PASSWORD_SET_REQUEST,
    USER_PASSWORD_SET_SUCCESS,
    USER_PASSWORD_SET_FAIL,
} from '../constants/userConstants'

export const login = (email, password) => async(dispatch) => {
    try {
        dispatch ({
            type: USER_LOGIN_REQUEST
        })
       
       const config = {
            headers:{
                'Content-type': 'application/json'
            }
       }

       const {data} = await axios.post(
            // 'http://127.0.0.1:8000/accounts/users/login/',
            '/accounts/users/login/',
            {'username': email, 'password': password},
            config
            )

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })

        localStorage.setItem('userInfo',JSON.stringify(data))

    } catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const logout = () => (dispatch) => {
    localStorage.removeItem('userInfo');
    dispatch({type:USER_LOGOUT});
}

export const register = (first_name, last_name, email, password, org_code) => async (dispatch) => {
    try {
        dispatch({
            type: USER_REGISTER_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }

        const { data } = await axios.post(
            // 'http://127.0.0.1:8000/accounts/users/register/',
            '/accounts/users/register/',
            { 'first_name': first_name, 'last_name': last_name, 'email': email, 'password': password, 'orgCode': org_code },
            config
        )
 
        dispatch({
            type: USER_REGISTER_SUCCESS,
            payload: data
        })

        localStorage.setItem('recentlyRegistered', 'true');

    } catch (error) {
        dispatch({
            type: USER_REGISTER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateUserPassword = (currentPassword, newPassword) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_UPDATE_PASSWORD_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            // `http://127.0.0.1:8000/accounts/users/profile/update/`,
            `accounts/users/profile/update/`,
            { 'currentPassword': currentPassword, 'newPassword': newPassword },
            config
        )

        dispatch({
            type: USER_UPDATE_PASSWORD_SUCCESS,
            payload: data
        })

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: { ...userInfo, password: newPassword }
        })

        localStorage.setItem('userInfo', JSON.stringify({ ...userInfo, password: newPassword }))

    } catch (error) {
        dispatch({
            type: USER_UPDATE_PASSWORD_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const forgotPassword = (email) => async (dispatch) => {
    try {
        dispatch({ type: USER_PASSWORD_RESET_REQUEST });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axios.post(
            // 'http://127.0.0.1:8000/accounts/users/request_password_reset_email/',
            '/accounts/users/request_password_reset_email/',
            { 'email': email },
            config
        )        

        dispatch({
            type: USER_PASSWORD_RESET_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: USER_PASSWORD_RESET_FAIL,
            payload: error.response && error.response.data.detail
                   ? error.response.data.detail
                   : error.message
        });
    }
};

export const setUserPassword = ({ uidb64, token, newPassword }) => async (dispatch) => {
    try {
        dispatch({ type: USER_PASSWORD_SET_REQUEST });

        const config = {
            headers: {
                'Content-Type': 'application/json'
            },
        };

        const { data } = await axios.put(
            // `http://127.0.0.1:8000/accounts/set-user-password/${uidb64}/${token}/`,
            `/accounts/set-user-password/${uidb64}/${token}/`,
            { 'newPassword': newPassword },
            config
        )

        dispatch({
            type: USER_PASSWORD_SET_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: USER_PASSWORD_SET_FAIL,
            payload: error.response && error.response.data.message
                   ? error.response.data.message
                   : error.message
        });
    }
};
