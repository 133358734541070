import Header from './components/Header'
import Footer from './components/Footer'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import HomeScreen from './screens/HomeScreen'
import LoginScreen from './screens/LoginScreen'
import RegisterScreen from './screens/RegisterScreen'
import RegisterSuccessScreen from './screens/RegisterSuccessScreen'
import VerificationSuccessScreen from './screens/VerificationSuccessScreen'
import FailureScreen from './screens/FailureScreen'
import ProfileScreen from './screens/ProfileScreen'
import ResetPasswordScreen from './screens/ResetPasswordScreen'
import ForgotPasswordEmailScreen from './screens/ForgotPasswordEmailScreen'
import ResetPasswordUserScreen from './screens/ResetPasswordUserScreen'

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path='/' element={<HomeScreen />} exact />
        <Route path='/login' element={<LoginScreen />} />
        <Route path='/register' element={<RegisterScreen />} />
        <Route path='/register-success' element={<RegisterSuccessScreen />} />
        <Route path='/verification-success' element={<VerificationSuccessScreen />} />
        <Route path='/failure' element={<FailureScreen />} />
        <Route path='/profile' element={<ProfileScreen />} />
        <Route path='/reset-password' element={<ResetPasswordScreen />} />
        <Route path='/forgot-password' element={<ForgotPasswordEmailScreen />} />
        <Route path='/reset-password-user' element={<ResetPasswordUserScreen />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
