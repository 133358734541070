import React from 'react'

function HomeScreen() {
  return (
    <section id="hero" className="d-flex align-items-center">
  
      <div className="container">
        <div className="row">
          <div className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
            <h1>Effortless HR and HCM Solutions</h1>
            <h2>Learn how you can amplify your success by choosing the right platform for your organization.</h2>
            <div className="d-flex">
              <a href="#about" className="btn-get-started scrollto">Get Started</a>
              <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="glightbox btn-watch-video"><i className="bi bi-play-circle"></i><span>Watch Video</span></a>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2 hero-img">
            <img src="static/img/img1.png" className="img-fluid animated" alt="" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomeScreen
